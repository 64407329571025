import { AdminFormInfo, ApplicationFormData, ApplicationFormInfo, AdminFormData } from '@/lib/dto';
import { ApplicationDraftSaveKeys, AdminSaveKeys, ApplicationReviewType } from '@/lib/dto/keys';
import { ApplicationType } from '@common/seller-application/enum/applicationType.enum';
import { useAuthRequest } from '@/hooks/use-request';
import { message } from 'antd';
import { FetchReviewDTO } from '@/dto/Review';
import { useTranslation } from 'react-i18next';
import { ObligorInfoEntry } from '@/dto/Obligor';
import useSWR from 'swr';
import _ from 'lodash';
import { Action } from '@common/seller-application/enum/action.enum';
import { ACTION_FORM_SAVED } from '@components/form/form-logic/CommonFormLogic';

export interface DraftSaveOption {
  applicationStage?: number;
  keyset?: ApplicationReviewType;
}

export const useApplicationFormAPI = (_applicationId?: string) => {
  const applicationId = _applicationId;
  const { api, fetcher } = useAuthRequest();
  const { t } = useTranslation();

  const fetchApplication = async (): Promise<ApplicationFormInfo> => {
    return await (await api())
      .setAPI(`/application/${applicationId}`)
      .setMethod('GET')
      .fetchData();
  };

  const fetchAdminApplication = async (): Promise<AdminFormInfo> => {
    return await (await api())
      .setAPI(`/application/${applicationId}`)
      .setMethod('GET')
      .fetchData();
  };

  const submitDraft = async (dataset: Partial<ApplicationFormData>, { applicationStage, keyset }: DraftSaveOption): Promise<boolean> => {
    const actualKeys = AdminSaveKeys?.[keyset] ?? ApplicationDraftSaveKeys;
    const savedDataset = _.pick(dataset, actualKeys);

    console.log('Submitting Draft with keys', actualKeys);

    //////////////////////////////////////////////////////////////////////////////////////

    const actualStage = Math.min(Math.max(1, applicationStage), 7);
    if (actualStage !== applicationStage) console.log('Warn: stage mismatched');
    console.log('Submit Draft with stage', actualStage);

    //////////////////////////////////////////////////////////////////////////////////////
    const res = await (await api())
      .setAPI(`/application/draft/${applicationId}`)
      .setMethod('PUT')
      .setData({ actualStage, ...savedDataset })
      .fetchResponse();
    if (res.isSuccessful === false) {
      message.error(res.errorMessage);
    } else {
      message.success(t('common.operation.draftsaved'));
    }
    return res.isSuccessful;
  };
  const submitApplication = async (dataset: ApplicationFormData, dryRun?: boolean): Promise<boolean> => {
    const rrs = await submitDraft(dataset, { applicationStage: 8 });
    if (!rrs) return false;
    const res = await (await api())
      .setAPI(`/application/submit/${applicationId}`)
      .setMethod('POST')
      .fetchData();
    if (!res) return false;
    message.success(t('common.operation.submitted'));
    return true;
  };

  const submitReview = async (
    dataset: Partial<AdminFormData>,
    review: ApplicationReviewType,
    action: string,
    comment: string,
    dryRun?: boolean
  ): Promise<boolean> => {
    if (action !== 'RETURN') {
      const rss = await submitDraft(dataset, { keyset: review });
      if (!rss) return false;
    }
    const res = await (await api())
      .setAPI(`/application/review/${applicationId}`)
      .setMethod('POST')
      .setData({
        whomReview: review,
        action,
        message: comment,
      })
      .fetchResponse();
    if (res.isSuccessful === false) {
      message.error(res.errorMessage);
      return false;
    }
    if (Action.RETURN === action) message.success(t('common.operation.return'));
    else message.success(t('common.operation.review', { action, review }));
    return true;
  };

  const submitSellerMigrate = async (dryRun?: boolean): Promise<boolean> => {
    const res = await (await api())
      .setAPI(`/application/approve/${applicationId}` + (!!dryRun ? `?dryRun=TRUE` : ''))
      .setMethod('POST')
      .fetchResponse(true);
    if (!res.isSuccessful) return false;
    if (!dryRun) message.success(t('common.operation.migrate'));
    return true;
  };

  const fetchReviewState = async (): Promise<FetchReviewDTO> => {
    const res = await (await api())
      .setAPI(`/application/review/${applicationId}/list`)
      .setMethod('GET')
      .fetchData<FetchReviewDTO>();
    return res;
  };

  const createApplication = async (): Promise<boolean> => {
    const resp = await (await api())
      .setAPI('/application')
      .setMethod('POST')
      .fetchResponse();
    if (!resp.isSuccessful) return false;
    message.success(t('common.operation.created'));
  };

  const useObligorInfo = () => useSWR<ObligorInfoEntry[]>(`/application/${applicationId}/obligor`, fetcher);

  const modifyApplicationType = async (applicationType: ApplicationType): Promise<boolean> => {
    const applicationNumber = await (await api())
      .setAPI(`/application/${applicationId}?applicationType=${applicationType}`)
      .setMethod('PUT')
      .fetchData();
    if (!applicationNumber) return false;
    message.success(
      t('common.operation.modify.application.type', {
        applicationType,
        applicationNumber,
      })
    );
    return true;
  };

  const autoFillApplication = async (): Promise<boolean> => {
    const applicationNumber = await (await api())
      .setAPI(`/application/auto-fill/${applicationId}`)
      .setMethod('PUT')
      .fetchData();
    if (!applicationNumber) return false;
    message.success(
      t('common.operation.auto-fill', {
        applicationNumber,
      })
    );
    return true;
  };

  return {
    fetchAdminApplication,
    fetchApplication,
    submitDraft,
    submitApplication,
    submitReview,
    fetchReviewState,
    createApplication,
    useObligorInfo,
    submitSellerMigrate,
    modifyApplicationType,
    autoFillApplication,
  };
};
