import React from 'react';
import { ApplicationStatus } from '@/dto/Application';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

export interface StatusProps {
  status?: ApplicationStatus;
}

export const Status: React.FunctionComponent<StatusProps> = ({ status }: StatusProps) => {
  const { t } = useTranslation();
  const className = `status status--${status?.toLowerCase()}`;
  const i18name = `application.status.${status?.toLowerCase().replace('_', '-')}`;

  return (
    <span>
      <Tag key={status} className={className}>
        {t(i18name)}
      </Tag>
      <style jsx global>{`
        .status {
          border-radius: 4px;
        }

        .status--draft {
          color: #1890ff;
          border: 1px solid #91d5ff;
          background: #e6f7ff;
        }

        .status--approved {
          color: #52c41a;
          border: 1px solid #b7eb8f;
          background: #f6ffed;
        }

        .status--under_review {
          color: #faad14;
          border: 1px solid #ffe58f;
          background: #fffbe6;
        }

        .status--rejected {
          color: #ff4d4f;
          border: 1px solid #ffa39e;
          background: #fff1f0;
        }

        .status--to_be_verified {
          color: #ff7a45;
          border: 1px solid #ffbb96;
          background: #fff2e8;
        }

        .status--accepted {
          color: #13a8a8;
          border: 1px solid #87e8de;
          background: #e6fffb;
        }

        .status--to_be_signed {
          color: #9254de;
          border: 1px solid #d3adf7;
          background: #f9f0ff;
        }
      `}</style>
    </span>
  );
};
