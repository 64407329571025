import React from 'react';
import { Button, Card, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { ApplicationDTO } from '@/dto/Application';
import { Status } from './Status';
import { FormattedDate } from './Date';
import Link from 'next/link';
import { PlusOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { useAuthRequest } from '@/hooks/use-request';
import { useApplicationFormAPI } from '@/api/ApplicationForm';
import { useAuth } from '@/hooks/use-auth';
import { User } from '@/lib/user';
import { isArray } from 'util';
import _ from 'lodash';
import { ApplicationType } from '@common/seller-application/enum/applicationType.enum';
import { ApplicationStatus } from '@common/seller-application/enum/applicationStatus.enum';

export interface ApplicationListProps {
  dataStore?: ApplicationDTO[];
}

function getRoutePrefix(record: ApplicationDTO): string {
  if(record.applicationType === ApplicationType.NORMAL) {
    return 'application'
  } else if (record.applicationType === ApplicationType.EXPRESS) {
    return 'express-application';
  } else if (record.applicationType === ApplicationType.POS) {
    return 'pos-application';
  }else {
    return 'application'; // Default value
  }
}

export const ApplicationList: React.FunctionComponent<ApplicationListProps> = ({ dataStore }: ApplicationListProps) => {
  const { fetcher } = useAuthRequest();
  const { user, loading } = useAuth();
  const { createApplication } = useApplicationFormAPI();
  const { t } = useTranslation();
  const { data, mutate } = useSWR<ApplicationDTO[]>('/application', fetcher, {
    initialData: dataStore,
  });

  const onCreateApplication = async (): Promise<void> => {
    await createApplication();
    mutate();
  };

  return (
    <Card title={t('application-list.title')}>
      <div style={{ margin: '0px 40px' }}>
        <Table<ApplicationDTO>
          loading={loading || !data }
          dataSource={data}
          rowKey='id'
          title={() => (
            <Button 
              icon={<PlusOutlined />} 
              type='primary' 
              onClick={onCreateApplication} 
              disabled={(data && data.length !== 0 && data.some(item=>item.status === ApplicationStatus.DRAFT))}
            >
              {t('application-list.new.application')}
            </Button>
          )}
          pagination={false}>
          <Table.Column
            dataIndex='applicationNo'
            key='applicationNo'
            title={t('application-list.application.no')}
            render={(value: string, record: ApplicationDTO): JSX.Element => (
              <Link as={`/${getRoutePrefix(record)}/${record.id}`} href={`/${getRoutePrefix(record)}/[aid]`}>
                <a>{value}</a>
              </Link>
            )}
          />
          <Table.Column
            dataIndex='createDate'
            key='createDate'
            title={t('application-list.application.create-date')}
            render={(value): JSX.Element => <FormattedDate date={value} />}
          />
          <Table.Column
            dataIndex='updateDate'
            key='updateDate'
            title={t('application-list.application.update-date')}
            render={(value): JSX.Element => <FormattedDate date={value} />}
          />
          <Table.Column
            dataIndex='status'
            key='status'
            title={t('application-list.application.status')}
            render={(value): JSX.Element => <Status status={value} />}
          />
          <Table.Column dataIndex='remarks' key='remarks' title={t('application-list.application.remarks')} />
        </Table>
      </div>
    </Card>
  );
};
