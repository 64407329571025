import React from 'react';
import {Button, Card, message, Table, Tooltip} from 'antd';
import {useTranslation} from 'react-i18next';
import {ApplicationDTO} from '@/dto/Application';
import {Status} from './Status';
import {FormattedDate} from './Date';
import Link from 'next/link';
import useSWR from 'swr';
import {useAuthRequest} from '@/hooks/use-request';
import {ContainerOutlined, CopyOutlined} from '@ant-design/icons';
import {ApplicationType} from '@common/seller-application/enum/applicationType.enum';
import {useAuth} from "@/hooks/use-auth";
import {Role} from "@common/seller-application/enum/role.enum";

export interface AdminListProps {
  dataStore?: ApplicationDTO[];
}

function getRoutePrefix(record: ApplicationDTO): string {
  if (record.applicationType === ApplicationType.NORMAL) {
    return 'admin'
  } else if (record.applicationType === ApplicationType.EXPRESS) {
    return 'express-admin';
  } else if (record.applicationType === ApplicationType.POS) {
    return 'pos-admin';
  } else {
    return 'admin'; // Default value
  }
}

export const AdminList: React.FunctionComponent<AdminListProps> = ({ dataStore }: AdminListProps) => {
  const { t } = useTranslation();
  const { fetcher } = useAuthRequest();
  const { data, mutate } = useSWR<ApplicationDTO[]>('/application', fetcher, { initialData: dataStore });
  const { data: referralCode, isValidating } = useSWR<string>('/user/referral', fetcher);

  return (
    <>
      <Card
        title={
          <>
            {t('application-list.title')} &nbsp;
            <small>{t('application-list.admin.mode')}</small>
            <div style={{ float: 'right' }}>
              <Tooltip placement='top' title={referralCode}>
                <Button
                  onClick={() => navigator.clipboard.writeText(referralCode).then(() => message.success(t('common.operation.copy')))}
                  icon={<CopyOutlined />}
                  loading={isValidating}
                  style={{ marginRight: '8px' }}
                  type='primary'>
                  {t('application-list.admin.sales.referral')}
                </Button>
              </Tooltip>
              <Link href='/admin/partner/referral'>
                <Button icon={<ContainerOutlined />} type='primary'>
                  {t('application-list.admin.partner.referral')}
                </Button>
              </Link>
            </div>
          </>
        }>
        <div style={{ margin: '20px 40px' }}>
          <Table<ApplicationDTO> loading={!data} dataSource={data} rowKey='id' pagination={{ position: 'bottomRight' }}>
            <Table.Column
              dataIndex='applicationNo'
              key='applicationNo'
              title={t('application-list.application.no')}
              render={(value: string, record: ApplicationDTO): JSX.Element => (
                <Link as={`/${getRoutePrefix(record)}/${record.id}`} href={`/${getRoutePrefix(record)}/[aid]`}>
                  <a>{new String(value).padStart(8, '0')}</a>
                </Link>
              )}
            />
            <Table.Column
              dataIndex='mainlandCompanyName'
              key='mainlandCompanyName'
              title={t('application-list.application.mainlandcompanyname')}
              ellipsis={true}
            />
            <Table.Column
              dataIndex='hongkongCompanyName'
              key='hongkongCompanyName'
              title={t('application-list.application.hongkongcompanyname')}
              ellipsis={true}
            />
            <Table.Column
              dataIndex='applicationType'
              key='applicationType'
              title={t('application-list.application.applicationType')}
            />
            <Table.Column
              dataIndex='createDate'
              key='createDate'
              title={t('application-list.application.create-date')}
              render={(value): JSX.Element => <FormattedDate date={value} />}
            />
            <Table.Column
              dataIndex='updateDate'
              key='updateDate'
              title={t('application-list.application.update-date')}
              render={(value): JSX.Element => <FormattedDate date={value} />}
            />
            <Table.Column
              dataIndex='status'
              key='status'
              title={t('application-list.application.status')}
              render={(value): JSX.Element => <Status status={value} />}
            />
          </Table>
        </div>
      </Card>
    </>
  );
};
