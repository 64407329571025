export const ApplicationDraftSaveKeys = [
  'applicationStage',
  'mainlandCompany',
  'hongkongCompany',
  'mainlandCompanyLegalRep',
  'mainlandDirectorList',
  'mainlandShareholderList',
  'hongkongDirectorList',
  'hongkongShareholderList',
  'contactPersonList',
  'hongkongFinancialReportList',
  'mainlandFinancialReportList',
  'borrowingDetails',
  'amazonStoreList',
  'ebayStoreList',
  'shopeeStoreList',
  'lazadaStoreList',
  'wishStoreList',
  'shopifyStoreList',
  'shoplineStoreList',
  'shoplazzaStoreList',
  'tiktokInfo',
  'documentUpload',
  'tmallInfo',
  'shopifyInfo',
  'confirmInfo',
  'mercadoInfo',
];

export enum ApplicationReviewType {
  accountManagerReview = 'accountManagerReview',
  creditReview = 'creditReview',
}

export const AdminSaveKeys: Record<ApplicationReviewType, string[]> = {
  accountManagerReview: ['partnerInfo', 'salesInfo'],
  creditReview: ['partnerInfo', 'creditInfo'],
};