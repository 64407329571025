import { pageRequireAuth, useAuth } from '@/hooks/use-auth';
import { FullPage } from '@components/layout/FullPage';
import { ApplicationList } from '@components/listing/ApplicationList';
import { NextPage } from 'next';
import React from 'react';
import { PowerUserRoles } from '@/lib/user';
import { AdminList } from '@components/listing/AdminList';

const AppPage: NextPage = () => {
  const { checkUserHasGroup } = useAuth();
  if (checkUserHasGroup(...PowerUserRoles))
    return (
      <FullPage>
        <AdminList />
      </FullPage>
    );
  else
    return (
      <FullPage>
        <ApplicationList />
      </FullPage>
    );
};

export default pageRequireAuth(AppPage, [], true);
