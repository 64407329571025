import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime, DateTimeFormatOptions } from 'luxon';

type OptionalDate = Date | string;

interface FormattedDateProps {
  date: OptionalDate;
  format?: DateTimeFormatOptions;
}

export const FormattedDate: React.FunctionComponent<FormattedDateProps> = ({ date, format = DateTime.DATETIME_SHORT }: FormattedDateProps) => {
  const { i18n } = useTranslation();
  const formatted = DateTime.fromJSDate(new Date(date), {
    locale: i18n.language,
  }).toLocaleString(format);
  return <span>{formatted}</span>;
};
